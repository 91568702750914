/*!
 * FSO-Bootstrap v2.3.1 (https://uarizona-fnsv.github.io/bootstrap/)
 * Copyright 2017-2023 FSO IT - FAST Team
 * Licenced under MIT (https://github.com/uarizona-fnsv/bootstrap/blob/main/LICENSE)
 * A theme for Bootstrap
 */

// Additions and Tweaks

@import "variables";

// Bootstrap (imported here so that our variables override bootstrap defaults)
@import "../node_modules/bootstrap/scss/bootstrap";

// Overrides
@import "colors";
